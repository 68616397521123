@import "../../utilities/named_var.scss";

.Products {
  text-align: center;
  color: $page-color;
  text-align: center;
  background-color: white;
  margin: 0;
  padding: 0;

  .carousel-item {
    overflow: visible;
    background-color: rgba(0, 0, 0, 0);
    img {
      flex-shrink:0;
      -webkit-flex-shrink: 0;
      max-width:70%;
      max-height:90%;
    }
  }
  .carouselImg {
    width:100%;
    height:40vw;
    max-height: 600px;
    display:flex;
    justify-content:center;
    align-items:center;
    overflow:hidden
  }

  h2 {
    color: $challenge-blue;
    text-align: left;
  }
  h3 {
    margin: 10px;
    color: $challenge-blue;
  }

  .product-description {
    text-align: center;
    p {
      font-weight: bold;
    }
  }
}

.card {
  width: 90vw;
  margin: 10px;
  margin-left: auto;
  margin-right: auto;
}

p span {
  font-size: calc(clamp(18px, 2vw, 22px));
}

.card-body {
  height: 100%;
  background-color: rgb(235, 235, 235);
}

.ProductsHeader {
  padding-top: 4vw;
  padding-bottom: 4vw;
  h1 {
    padding: 10px;
    font-size: calc(clamp(32px, 4vw, 60px));
    color: $challenge-orange;
  }
  p {
    padding-bottom: 18px;
    text-align: center;
    font-size: calc(clamp(16px, 1vw, 20px));
    width: calc(clamp(30px, 80%, 1000px));
    height: auto;
    margin: auto;
  }
  background-color: $page-background-2;
}

.card-img {
  background-color: white;
  height: 250px;
  width: auto;
}
#img-col {
  display: flex;
  justify-content: center;
  align-items: center;
}
#p_menu {
  height: 100%;
  width: 20vw;
  min-width: 200px;
}

.containerImage {
  display: relative;
}
.cardLoad {
  position: relative;
  transition: opacity 1s;
  z-index: 100;
}
.spinner-border {
  position: relative;
  color: blue;
  z-index: 101;
}

#product_s1 {
  padding-top: 1vw;
  padding-bottom: 1vw;
  h1 {
    padding: 10px;
    color: $challenge-orange;
  }
  p {
    padding-bottom: 18px;
    text-align: center;
    width: calc(clamp(30px, 80%, 1000px));
    height: auto;
    margin: auto;
  }
  background-color: $page-background-2;
}

#product_s2 {
  padding-top: 1vw;
  padding-bottom: 1vw;
  h1 {
    padding: 10px;
    color: $challenge-blue;
  }
  p {
    padding-bottom: 18px;
    text-align: center;
    width: calc(clamp(30px, 80%, 1000px));
    height: auto;
    margin: auto;
  }
}

#product_s3 {
  padding-top: 1vw;
  padding-bottom: 1vw;
  h1 {
    padding: 10px;
    color: $challenge-blue;
  }
  p {
    padding-bottom: 18px;
    text-align: center;
    width: calc(clamp(30px, 80%, 1000px));
    height: auto;
    margin: auto;
  }
}

#product_s4 {
  padding-top: 1vw;
  h1 {
    color: $challenge-orange;
  }
  p {
    padding-bottom: 18px;
    text-align: center;
    width: calc(clamp(30px, 80%, 1000px));
    height: auto;
    margin: auto;
  }
}