@import 'utilities/named_var';

.flex-wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
}

#root {
  min-height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
}

// Size constraints
@media screen and (max-width: 690px) {
  .logo {
    display: none !important;
  }
  .login-txt {
    display: none !important;
  }
}

@media screen and (min-width: 691px) {
  .cmc-name {
    display: none !important;
  }
}

.table {
  margin-bottom: 0 !important;
}

td,
tr {
  min-width: 150px;
}

.scrollImage {
  width: auto;
  margin-left: auto;
  margin-right: auto;
  overflow: auto;
}

.fixedTable {
  width: auto;
  max-height: 600px;
  margin: 0;
  padding: 0;
  margin-top: 1em;
  margin-bottom: 1em;
  border-style: solid;
  border-color: $s3_background;
  margin-left: auto;
  margin-right: auto;
  overflow: auto;
}

@media (max-width: 900px) {
  .scrollImage,
  .fixedTable {
    border-style: solid;
    border-color: $s3_background;
    width: 82vw;
  }
}
// header and body
.page-content {
  height: auto;
}

// Header items
.header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: $page-background;
  color: white;
  font-size: 24px;
  text-align: center;
  #exp-menu {
    background-color: white;
  }

  #center-header {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .Menu {
    padding: 0;
    margin: 0;
  }

  .cmc-name {
    font-size: 20px;
  }

  .off-canvas {
    background-color: white;
    color: white;
  }

  a {
    color: white;
    font-size: calc(clamp(16px, 1.6vw, 28px));
    text-decoration: none;
  }

  a:hover {
    color: rgb(177, 177, 177);
    transition: 0.2s;
  }

  .nav-link:focus {
    color: $challenge-orange;
  }

  #drop-nav a {
    color: rgb(124, 124, 124);
  }

  #drop-nav a:hover {
    color: $challenge-blue;
  }

  #drop-nav > a.active {
    color: white;
  }
}

// so cards don't have highlighted links when wrapped in linker
#card-item {
  text-align: center;
  text-decoration: none;
  color: black;
}

#card-text {
  font-size: calc(clamp(18px, 2vw, 22px));
}

#card-title {
  margin: 10px;
  margin-bottom: calc(clamp(10px, 6vw, 50px));
  text-align: center;
  font-size: calc(clamp(22px, 3vw, 28px));
}

// Images and SVG(s)

.logo {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  height: 70px;
  max-height: 80px;
}

.menuIcon {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 36px;
  min-height: 36px;
  fill: white;
  transition: 0.2s;
}

.menuIcon:hover {
  fill: $challenge-blue;
}

.drop-toggle {
  background: none;
  color: none;
  background-color: none;
}

.login {
  .login-txt {
    display: inline;
  }
  .person {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 40px;
    width: 40px;
    margin-right: 1vw;
    justify-self: right;
  }
  .human {
    fill: lightgray;
  }
}

.login:hover {
  display: inline-block;
  .human {
    transition: 0.2s;
    fill: $challenge-blue;
  }
}

// Footer Items
.footer {
  margin-top: auto;
  width: 100%;
  text-align: center;
  background-color: $page-background-2;
}

#foot-content p {
  color: $page-faded-2;
  text-align: center;
  margin: 0px;
}

#foot-nav {
  text-align: center;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  > * {
    align-self: center;
    justify-content: center;
    align-items: center;
  }
  a {
    color: $page-color-2;
  }
  a:hover {
    color: $challenge-blue;
    transition: 0.2s;
  }
}

// Images and SVG(s)
.sm_logo {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 9vw;
  min-width: 60px;
  max-width: 160px;
  transition: 0.2s;
}
.sm_logo:hover {
  width: 9.3vw;
  min-width: 65px;
  max-width: 165px;
}

.social {
  display: flexbox;
  width: 100%;
  text-align: left;
  margin-bottom: 8px;
  h2 {
    font-size: 1em;
    color: gray;
    margin: 5px;
  }
  .social-link {
    display: flexbox;
    fill: gray;
    margin: 5px;
    > * {
      margin-top: 5px;
      margin-right: 12px;
      width: 20px;
      height: 20px;
    }
    a,
    a:hover {
      fill: gray;
    }
    #twitter:hover {
      fill: #00acee;
      transition: 0.2s;
    }
    #facebook:hover {
      fill: #3b5998;
      transition: 0.2s;
    }
    #instagram:hover {
      fill: #8a3ab9;
      transition: 0.2s;
    }
    #linkedin:hover {
      fill: #0e76a8;
      transition: 0.2s;
    }
  }
}

.s1 {
  padding-top: 2em;
  padding-bottom: 2em;
  h1 {
    padding: 10px;
    color: $challenge-orange;
  }
  p {
    padding-bottom: 18px;
    text-align: center;
    width: calc(clamp(30px, 80%, 1000px));
    height: auto;
    margin: auto;
  }
  background-color: $page-background-2;
}
.s2 {
  background-color: white;
  padding-top: 2em;
  padding-bottom: 2em;
  text-align: center;
  h1,
  h2,
  h3 {
    padding: 10px 0;
    color: $challenge-blue;
  }
  p {
    padding-bottom: 18px;
    text-align: center;
    width: calc(clamp(30px, 80%, 700px));
    height: auto;
    margin: auto;
  }
  span {
    font-weight: bold;
  }
}
.s3 {
  background-color: $s3_background;
  padding-top: 2em;
  padding-bottom: 2em;
  text-align: center;
  h1,
  h2,
  h3 {
    padding: 10px 0;
    color: white;
  }
  p {
    color: white;
    padding-bottom: 18px;
    text-align: center;
    width: calc(clamp(30px, 80%, 1000px));
    height: auto;
    margin: auto;
  }
  span {
    font-weight: bold;
  }
}
