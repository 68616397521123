@import '../../utilities/named_var.scss';

div .Dealers {
  padding: 0;
  margin: 0;
  color: $page-color;
  text-align: center;
  background-color: $page-background-2;

  .col {
    margin: 1em;
  }

  p {
    font-weight: normal;
    font-size: 1em;
    margin-bottom: 0;
  }

  hr {
    height: 2px !important;
    color: gray !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  span {
    font-weight: normal;
    font-size: 1em;
  }

  .vr {
    height: 100%;
  }
}

.dealerInfoWindow {
  width: 90%;
  height: 90%;
  padding: 10px 10px 0px 10px;
  margin-bottom: 10px;
  border: 1px solid rgb(166, 166, 166);
  img {
    width: 100%;
  }
}

#dealerBlock {
  min-width: 120px;

  p {
    font-weight: normal;
    font-size: 1em;
    padding-bottom: 0;
    margin: auto;
  }
}

.DealerLogo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 28vw;
  border: none;
}
