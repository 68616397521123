@import '../../utilities/named_var.scss';

div .About {
  margin: 0;
  color: $page-color;
  text-align: center;
  background-color: $page-background-2;

  .card-body {
    background-color: white;
  }

  p {
    font-weight: normal;
    font-size: 1em;
  }

  .col {
    margin: 1em;
  }

  .infoCol {
    h2,
    h3 {
      text-align: center;
    }
    p,
    ul {
      text-align: left;
      margin: 0;
      width: 100%;
    }
    max-width: 1000px;
    text-align: left;
  }

  ul {
    text-align: center;
  }

  li {
    margin: 1em;
  }

  hr {
    height: 2px !important;
    color: gray !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  span {
    font-weight: normal;
    font-size: 1em;
  }

  .container {
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0;
  }

  .row {
    margin-left: 30px;
    margin-right: 30px;
  }

  .progress-bar {
    background-color: $challenge-orange;
  }

  .formStack {
    justify-content: center;
    width: auto;
    p {
      width: auto;
      margin: 0;
      padding: 0;
    }
  }

  .timelineEvent {
    svg {
      fill: royalblue;
    }

    .svgShown {
      transition: 0.2s;
      fill: tomato;
    }
  }
}
