$challenge-blue: #122e53;
$challenge-orange: #ef6701;

$page-background: rgb(43, 43, 51);
$page-color: black;

$page-background-2: rgb(230, 230, 230);
$page-color-2: rgb(124, 124, 124);
$page-faded-2: rgb(170, 170, 170);

$s3_background: rgb(151, 170, 206);
