@import "../../utilities/named_var.scss";

div .Info {
  margin: 0;
  color: $page-color;
  text-align: center;
  background-color: $page-background-2;

  .card-body {
    background-color: white;
  }

  p {
    font-weight: normal;
    font-size: 1em;
  }

  .col {
    margin: 0;
    max-width: 1000px
  }

  .infoCol {
    h2, h3, p, ul {
      width: 100%;
      text-align: left;
      margin: 0;
    }
    text-align: left;
  }

  ul {
    text-align: center;
  }

  li {
    margin: 1em;
  }

  hr {
    height: 2px !important;
    color: gray !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  span {
    font-weight: normal;
    font-size: 1em;
  }

}