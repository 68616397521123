@import '../../utilities/named_var.scss';

div .Home {
  color: $page-color;
  text-align: center;
  background-color: $page-background;
  margin: 0;
  padding: 0;
  p {
    margin: 0;
  }

  // Home Body
  #b1 {
    padding-top: 4vw;
    padding-bottom: 4vw;
    h1 {
      padding: 10px;
      color: $challenge-orange;
    }
    p {
      padding-bottom: 18px;
      text-align: center;
      width: calc(clamp(30px, 80%, 1000px));
      height: auto;
      margin: auto;
    }
    background-color: $page-background-2;
  }
  #b2 {
    background-color: white;
    padding-top: 4vw;
    padding-bottom: 4vw;
    text-align: center;
    h1,
    h2,
    h3 {
      padding: 10px;
      color: $challenge-blue;
    }
    p {
      padding-bottom: 18px;
      text-align: center;
      width: calc(clamp(30px, 80%, 1000px));
      height: auto;
      margin: auto;
    }
    span {
      font-weight: bold;
    }
  }
  #b3 {
    .row {
      height: 100%;
    }
    #b3-content {
      position: relative;
      height: 100%;
      display: block;
      align-content: center;
    }
    position: relative;
    padding-top: 4vw;
    padding-bottom: 4vw;
    background-color: rgb(31, 30, 44);
    h1 {
      padding: 10px;
      color: white;
    }
    p {
      color: white;
      padding-bottom: 18px;
      text-align: center;
      width: calc(clamp(30px, 80%, 1000px));
      height: auto;
      margin: auto;
    }
    &::before {
      content: '';
      display: block;
      position: absolute;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: white;
      background-image: url('./150-ribbon.png');
      background-size: calc(clamp(200px, 30vw, 380px));
      opacity: 0.2;
      background-position: 10% 4vh;
    }
  }
  .col {
    align-self: center;
    align-content: center;
  }

  #cmt-link {
    color: gray;
    fill: gray;
    text-decoration: none;
  }
  #cmt-link:hover {
    transition: 0.2s;
    color: $challenge-orange;
    fill: $challenge-orange;
  }
}

.show_slide {
  background-color: white;
}
.champ_slide {
  background-color: gray;
}
.carousel-item {
  display: flex;
  margin: 0;
  padding: 0;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.carousel-caption#cc0 {
  h1,
  h2,
  h3 {
    font-size: calc(clamp(18px, 2vw, 42px));
  }
  span {
    font-size: calc(clamp(22px, 2.4vw, 54px));
  }
  p {
    font-size: calc(clamp(12px, 2vw, 42px));
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
  }
  text-align: right;
  color: $challenge-blue;
}
.carousel-caption#cc1 {
  text-align: center;
  top: 65%;
  transform: translateY(-65%);
  h1,
  h2,
  h3 {
    font-size: calc(clamp(24px, 2.2vw, 42px));
  }
}
.carousel-caption#cc2 {
  h3 {
    font-size: calc(clamp(18px, 2vw, 42px));
  }
  p {
    font-size: calc(clamp(12px, 2vw, 42px));
  }
  text-align: center;
  color: white;
  top: 50%;
  transform: translateY(calc(clamp(-90%, -22vw, -50%)));
  margin-right: 24vw;
  margin-bottom: calc(clamp(5px, 5vw, 60px));
}
.carousel-caption#cc3 {
  h1,
  h2,
  h3 {
    font-size: calc(clamp(18px, 2vw, 42px));
  }
  span {
    font-size: calc(clamp(22px, 2.4vw, 54px));
  }
  p {
    font-size: calc(clamp(12px, 2vw, 42px));
  }
  text-align: center;
  color: white;
}
