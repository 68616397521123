@import "../../../utilities/named_var.scss";

div .Warranty {

  padding: 0;
  margin: auto;
  color: $page-color;
  text-align: center;
  background-color: $page-background-2;

  .form-control {
    min-width: 260px;
  }

  .col {
    margin: 1em;
    margin-bottom: 0;
  }
  input {
    margin-bottom: 8px;
  }
  form {
    text-align: left;
  }

  h2 {
    text-align: center;
  }

  p {
    font-weight: normal;
    font-size: 1em;
    margin-bottom: 0;
  }

  hr {
    height: 2px !important;
    color: gray !important;
    width: 100%;
    margin-top: 1em;
    padding: 0 !important;
  }

  span {
    font-weight: normal;
    font-size: 1em;
  }

  .vr {
    left:50%;
    top:10%;
    bottom:10%;
    border-left:1px solid
  }
}

#inputText {
  p {
    margin-left: 1em;
    margin-right: 1em;
    padding: 0;
    text-align: left;
  }
}